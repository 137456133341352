import React from 'react';

const Header = () => {
  const scrollToProjects = () => {
    document.getElementById('projectsCarousel').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header className="bg-gray-800 text-white p-4">
      <nav>
        <ul className="flex space-x-4">
          <li><a href="#discussProjectDiv"><button id="discussProjectBtn" className="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600">Discuss Project</button></a></li>
          <li><a href="#requestConsultDiv"><button id="requestConsultationBtn" className="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600">Request Consultation</button></a></li>
          <li><button onClick={scrollToProjects} className="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600">Projects</button></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;