import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Main.css'; // Import CSS for animations
import Hero from './Hero'; // Import Hero component
import Projects from './Projects'; // Import Projects component
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Main = () => {
  const [projectInput, setProjectInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [projectHtml, setProjectHtml] = useState('');
  const [firstMessageSent, setFirstMessageSent] = useState(false); // New state variable
  const chatWindowRef = useRef(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false); // New state variable

  const handleSubmit = () => {
    if (projectInput) {
      if (!firstMessageSent) {
        document.getElementById('chatInput').classList.add('fade-out');
      }
      const chat_history = [...chatHistory, { role: 'user', content: projectInput }];
      setChatHistory(chat_history);
      setProjectInput('');
      setIsWaitingForResponse(true); // Set waiting state to true
      const editedHtml = document.getElementById('projectDescriptionDiv').innerHTML;
      fetch('https://consult.iahmed.me/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Origin': 'https://consult.iahmed.me'
        },
        body: JSON.stringify({
          chat: chat_history,
          project: editedHtml
        })
      })
      .then(response => response.json())
      .then(data => {
        setChatHistory(data.chat);
        setProjectHtml(data.project);
        setFirstMessageSent(true); // Set the state to true after first message
        setIsWaitingForResponse(false); // Set waiting state to false
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleConsultationSubmit = (e) => {
    e.preventDefault();
    console.log(projectDescription);
    fetch('https://consult.iahmed.me/request_consultation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Origin': 'https://consult.iahmed.me'
      },
      body: new URLSearchParams({
        name,
        email,
        project_description: projectDescription // Send the value of the ReactQuill element
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setName('');
        setEmail('');
        setProjectDescription('');
        toast.success('Consultation request sent successfully!');
      }
    });
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [chatHistory]);

  return (
    <main className="bg-gray-100 min-h-screen">
      <Hero /> {/* Embed Hero component */}
      
      <div id="discussProjectDiv" className={`flex flex-col items-center ${firstMessageSent ? 'first-message-sent' : 'initial-state'} bg-white py-8`}>
        
        <section id="chatSection" className={`w-full md:w-1/2 p-4 border-r border-gray-300 bg-gray-50`}>
          <div id="chatWindow" ref={chatWindowRef} className={`h-96 overflow-y-auto border border-gray-300 p-2 ${firstMessageSent ? '' : 'hidden'}`}>
            {chatHistory.map((message, index) => (
              <div key={index} className={`p-2 ${message.role === 'user' ? 'text-right' : 'text-left'}`}>
                <p className={`p-2 rounded ${message.role === 'user' ? 'bg-blue-200' : 'bg-green-200'}`}>
                  {message.role === 'user' ? <i className="fas fa-user"></i> : <i className="fas fa-robot"></i>} {message.content}
                </p>
              </div>
            ))}
            {isWaitingForResponse && (
              <div className="p-2 text-left">
                <p className="bg-yellow-200 p-2 rounded"><i className="fas fa-spinner fa-spin"></i> Waiting for response...</p>
              </div>
            )}
          </div>
          
          <div className={`mb-4 ${firstMessageSent ? '' : 'chat-input-centered'}`} id="chatInput">
            <textarea
              id="projectInput"
              placeholder="What would you like done? Please describe your need and I will work with you to draft a project requirement. Instantly."
              value={projectInput}
              onChange={(e) => setProjectInput(e.target.value)}
              onKeyDown={handleKeyDown}
              className="w-full h-24 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-sky-500"
            />
            <button id="submitBtn" onClick={handleSubmit} className="bg-sky-500 text-white px-4 py-2 mt-2 rounded hover:bg-sky-600">Submit</button>
          </div>
        </section>

        <section id="projectSection" className={`w-full md:w-1/2 p-4 h-96 ${firstMessageSent ? '' : 'hidden'} bg-gray-50`}>
          <div id="projectDescriptionDiv" className="overflow-y-auto flex-col py-4" contentEditable="true" dangerouslySetInnerHTML={{ __html: projectHtml }} />
          <a href="#requestConsultDiv" onClick={() => setProjectDescription(document.getElementById('projectDescriptionDiv').innerHTML)}>
            <button id="requestConsultationBtn" className="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600">Request Consultation</button>
          </a>
        </section>
      </div>

      <Projects />
      
      <div id="requestConsultDiv" className={`flex flex-col items-center bg-white py-8`}>
        <form onSubmit={handleConsultationSubmit} className="w-full md:w-1/2 p-4 border border-gray-300 bg-white rounded shadow-md">
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="projectDescription" className="block text-gray-700">Project Description</label>
            <ReactQuill
              id="projectDescription"
              onChange={setProjectDescription}
              value={projectDescription}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>
          <button type="submit" className="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600">Submit</button>
        </form>
      </div>
      <ToastContainer />
    </main>
  );
};

export default Main;
