import React, { useState, useEffect } from 'react';
import './Hero.css'; // Import CSS for animations
// TODO: Static image loading. Make dynamic
import svg_ai from './img/hero/ai.svg';
import svg_webapps from './img/hero/webapps.svg';
import svg_consult from './img/hero/consult.svg';

const headings = [
  { text: "Automate with GenerativeAI", image:svg_ai, animation: "animation1" },
  { text: "Deploy webapps", image:svg_webapps, animation: "animation2" },
  { text: "Commision research & engineering", image:svg_consult, animation: "animation3" },
];

const Hero = () => {
  const [currentHeading, setCurrentHeading] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(200);

  useEffect(() => {
    const handleType = () => {
      const i = loopNum % headings.length;
      const fullText = headings[i].text;

      setDisplayedText(isDeleting ? fullText.substring(0, displayedText.length - 1) : fullText.substring(0, displayedText.length + 1));

      if (!isDeleting && displayedText === fullText) {
        setTimeout(() => setIsDeleting(true), 500);
      } else if (isDeleting && displayedText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setCurrentHeading((currentHeading + 1) % headings.length);
      }

      setTypingSpeed(isDeleting ? 20 : 20);
    };

    const timer = setTimeout(handleType, typingSpeed);

    return () => clearTimeout(timer);
  }, [displayedText, isDeleting, loopNum, typingSpeed]);

  const scrollToProjects = () => {
    document.getElementById('projectsCarousel').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section id="heroSection" className="h-screen flex flex-col md:flex-row bg-gray-100">
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center text-center animate-fade-in">
        <h1 className="text-4xl mb-4 text-sky-500 h-24">{displayedText}_</h1>
        <p className="text-lg mb-8">Specialist consultancy by Ibrahim Ahmed, PhD</p>
        <div className="flex space-x-4">
          <a href="#discussProjectDiv">
            <button id="discussProjectBtn" className="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600">Discuss Project</button>
          </a>
          <a href="#requestConsultDiv">
            <button id="requestConsultationBtn" className="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600">Request Consultation</button>
          </a>
        </div>
        <button onClick={scrollToProjects} className="bg-sky-500 text-white px-4 py-2 rounded hover:bg-sky-600 mt-4">View Projects</button>
      </div>
      <div className="w-full md:w-1/2 flex justify-center items-center mt-4 md:mt-0">
        <img key={currentHeading} src={`${headings[currentHeading].image}`} alt="Hero image" className="max-w-80 transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in"/>
      </div>
    </section>
  );
};

export default Hero;
