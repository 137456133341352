import React from 'react';
import custom_chat from './img/projects/custom_chat.png';
import webapps from './img/projects/sattrack.gif';
import ml_models from './img/projects/ml_models.gif';
import research from './img/projects/research.png';

const projects = [
  {
    heading: "Bespoke GenAI",
    description: "Deploy custom ChatGPT assistants internally with your data. Use AI for automatic visualization, summarization, reporting, and content generation.",
    image: custom_chat},
  {
    heading: "Machine learning solutions",
    description: "Train prediction models for business analytics and optimization. Understand future trends from past data. Deploy apps to automatically predict labels.",
    image: ml_models},
  {
    heading: "Custom web apps",
    description: "Design and deploy nimble web apps tailored to your needs. For example: custom forms with specific reporting, data visualization dashboards, personal blogs etc.",
    image: webapps},
  {
    heading: "Research",
    description: "Commision, compose, & distill specialist research and reports from academic and industrial literature. Consult for training or specialist technical contribution.",
    image: research},
];

const Projects = () => {
  return (
    <div id="projectsCarousel" className="carousel-container flex overflow-x-scroll space-x-4 p-4 h-80">
      {projects.map((project, index) => (
        <div key={index} className="carousel-item flex flex-none w-100 bg-white rounded-lg shadow-md overflow-hidden relative">
          <img src={project.image} alt={`Project ${index}`} className="object-cover h-full w-full" />
          <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 p-4 transition-opacity duration-300 hover:bg-opacity-95 focus:bg-opacity-95">
            <h3 className="text-lg font-bold text-white">{project.heading}</h3>
            <p className="text-white">{project.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Projects;
